import React, { useEffect, useRef, useState } from "react";
import useSWR from "swr";
import {
  isChipTypeArray,
  isEmpty,
  isStringArray,
} from "../../../../../../types/types";
import {
  useQueryParams,
  StringParam,
  ArrayParam,
  NumberParam,
} from "use-query-params";
import type { SortingRule, Row as RowType } from "react-table";
import { useDebounce } from "../../../../../../util/hooks";
import {
  DeleteButton,
  EditButton,
  ListGridToggleButton,
  PrimaryButtonWithPlusIcon,
  TertiaryTextButtonSmall,
} from "../../../../../../components/Buttons/Buttons";
import { SearchBar } from "../../../../../../components/SearchBar/SearchBar";
import { SlideOut } from "../../../../../../components/SlideOut/SlideOut";
import {
  PageWrapper,
  ContentWrapper,
  PageTitle,
  FullWidthHorizontalSeparator,
} from "../../../../../../layout/portalPageLayout";
import {
  formatDate,
  useStoreState,
  defaultHandleSort,
  useSupportedLanguages,
  toTitleCase,
} from "../../../../../../util/util";
import { endpoints } from "../../../../../../endpoints";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { Flex, Flex1, Flex2 } from "../../../../../../layout/FormLayout";
import { DropDown } from "../../../../../../components/DropDown/DropDown";
import { DropdownContainer } from "../../../../../../components/Layout/Layout";
import type {
  Assets,
  GridItemProps,
  PIMAssetsResponse,
  SupportedAssetCategoryType,
  SupportedAssetType,
} from "../../../../../../types/types.PIM";
import { Table } from "../../../../../../components/Table/Table";
import { Pagination } from "../../../../../../components/Pagination/Pagination";
import { EditAsset } from "./EditAsset";
import { DeleteAssetModal } from "./DeleteAsset";
import type { ChipType } from "../../../../../../components/Chips/Chips";
import { FilterBy } from "../../../../../../components/FilterBy/FilterBy";
import { chipsToStringArray } from "../../../../../../components/Filters/Filters";
import { useAuthContext } from "../../../../../../components/Auth";
import { WithPermission } from "../../../../../../components/WithPermission/WithPermission";
import { NewAssetForm } from "../../../../../SharedPages/ProductDetailPage/Assets/NewAssetForm";
import { AssetNameIcon } from "../../../../../SharedPages/ProductDetailPage/Assets/util";
import { appendFiltersToUrl } from "../../../../../SharedPages/OrganizationPage/ProductsList/ProductsList.util";
import type { AssetCategory, AssetType } from "../util/AssetsUtil";
import { EditOrGenerateProductDocument } from "../../../../../SharedPages/ProductDetailPage/Assets/EditOrGenerateProductDocuments/EditOrGenerateProductDocument";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";
import { useRoutePath } from "../../../../../../util/Routing";
import { GridAssetView } from "../GridAssetView/GridAssetView";
import { BulkActionDropdown } from "../../../../../../components/BulkActionDropdown/BulkActionDropdown";

const NewAssetFormContainer = styled.div`
  #new_asset_form_id {
    margin-top: 60px;
  }
`;

type TableProduct = {
  id: string | boolean;
  name: string;
};

function getObjArrayFromStringArray(strArr: string[]) {
  return strArr?.map((name) => ({ name }));
}

type ApplyFiltersToURL = {
  categoryType: string[];
  assetType: string[];
  params: URLSearchParams;
};

const applyFiltersToURL = ({
  categoryType,
  assetType,
  params,
}: ApplyFiltersToURL): URLSearchParams => {
  if (assetType) {
    appendFiltersToUrl({
      existingParams: params,
      paramsToAppend: assetType,
      key: "asset_type",
    });
  }
  if (categoryType) {
    appendFiltersToUrl({
      existingParams: params,
      paramsToAppend: categoryType.map((category) => category),
      key: "asset_category",
    });
  }
  return params;
};

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  column-gap: 8px;
`;

const DropDownContainerWrapper = styled(DropdownContainer)`
  .per_Page {
    > div {
      z-index: 3;
    }
  }
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  align-items: center;
`;

const AddAssetButtonWrapper = styled.div`
  flex-shrink: 0;
`;

const BulkActionWrapper = styled.div`
  flex-shrink: 0;
`;

type ConstructQuery = {
  baseUrl: string;
  query: string;
  assetType: string[];
  categoryType: string[];
};

/**
 * Assets list page.  Used for seller admin to manage all his assets.
 */
export function AssetList() {
  const [query, setQuery] = useQueryParams({
    q: StringParam,
    asset_type: ArrayParam,
    asset_category: ArrayParam,
    offset: NumberParam,
  });
  const [assetTypesFromURL, setAssetTypesFromURL] = useState(
    getObjArrayFromStringArray(
      ((query?.asset_type ?? []) as string[]).map((assetType) =>
        toTitleCase(assetType)
      )
    )
  );
  const [categoryTypesFromURL, setcategoryTypesFromURL] = useState(
    getObjArrayFromStringArray((query?.asset_category ?? []) as string[])
  );

  const [editRowData, setEditRowData] = useState<Assets>();
  const [deleteRowData, setDeleteRowData] = useState<any>();
  // searchQuery is loaded from the URL if q exists.
  const [searchQuery, setSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 1000);
  const [offset, setOffset] = useState(query?.offset ?? 0);
  const perPageItems = [10, 20, 50];
  const [perPage, setPerPage] = useState(10);
  const [tablePagination, setTablePagination] = useState({
    perPage: perPage,
    pageCount: 0,
    pageIndex: 0,
  });
  const [sortingRules, setSortingRules] = useState<{
    sortBy?: string;
    orderBy: "asc" | "desc";
  }>({
    orderBy: "asc",
  });
  const [selectedAssetType, setSelectedAssetType] = useState(
    isStringArray(query.asset_type) ? query.asset_type : []
  );
  const [selectedCategoryType, setSelectedCategoryType] = useState(
    isStringArray(query.asset_category) ? query.asset_category : []
  );
  const [showSlideOut, setShowSlideOut] =
    useState<"add" | "edit" | "delete" | "generate" | false>(false);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [assetTypesFilters, setAssetTypesFilters] = useState<
    SupportedAssetType[]
  >([]);
  const [assetCategoryTypesFilters, setAssetCategoryTypesFilters] = useState<
    SupportedAssetCategoryType[]
  >([]);
  const [tableData, setTableData] = useState<TableProduct[]>([]);

  const [list_grid_toggle, set_list_grid_toggle] =
    useState<"list" | "grid">("list");

  const { t } = useTranslation();

  const { hasPermission } = useAuthContext();

  const { tenant_id } = useStoreState();

  const { supported_languages } = useSupportedLanguages();

  const { adminPath } = useRoutePath();

  const product_document_ref = useRef<{ clear_poll_timeout: () => void }>(null);

  const history = useHistory();

  const handleSort = async (rules: SortingRule<object>[]) =>
    defaultHandleSort(rules, sortingRules, setSortingRules, setTableData);

  const changePerPage = (perPage: number) => {
    setPerPage(perPage);
    if (perPage > offset) {
      setOffset(0);
    }
  };

  const closeCreateSlideOut = () => setShowSlideOut(false);
  const closeDeleteModal = () => setShowDeleteModal(false);

  const handleClearFilters = () => {
    setQuery({
      asset_category: undefined,
      asset_type: undefined,
    });
    setSelectedAssetType([]);
    setSelectedCategoryType([]);
    mutate();
  };

  const handleDelete = (row: RowType<Assets>) => {
    setShowDeleteModal(true);
    setDeleteRowData(row?.original);
  };

  const handleEdit = (row: RowType<Assets>) => {
    if (row.original.is_generated) {
      setShowSlideOut("generate");
    } else {
      setShowSlideOut("edit");
    }
    setEditRowData(row?.original);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOffset(0);
    setSearchQuery(e.target.value);
  };
  const handleClearSearch = () => {
    setSearchQuery("");
    setQuery({ q: undefined });
    setOffset(0);
  };

  const changePage = (offset: number) => {
    setOffset(offset);
    setTableData([]);
  };

  const handleAssetType = ({
    values,
    filter,
  }: {
    values: ChipType[];
    filter: string;
  }) => {
    const assetTypes = chipsToStringArray(values).map((asset) =>
      asset.toLowerCase()
    );
    setSelectedAssetType(assetTypes);
    setQuery({ asset_type: assetTypes });
    setOffset(0);
  };

  const handleCategoryType = ({
    values,
    filter,
  }: {
    values: ChipType[];
    filter: string;
  }) => {
    let categoryTypes = chipsToStringArray(values);
    setSelectedCategoryType(categoryTypes);
    setQuery({ asset_category: categoryTypes });
    setOffset(0);
  };

  /**
   * construct query from selected filters,
   * add search term if it exists
   */
  function constructQuery({
    baseUrl,
    query,
    assetType,
    categoryType,
  }: ConstructQuery) {
    const paramsWithOffset = new URLSearchParams(
      `offset=${offset}&limit=${perPage}&show_generated_assets=true`
    );
    if (query) paramsWithOffset.append("q", query);
    paramsWithOffset.append("order_by", sortingRules.orderBy || "asc");
    if (sortingRules.sortBy) {
      paramsWithOffset.append("sort_by", sortingRules.sortBy);
    }
    return (
      baseUrl +
      "?" +
      applyFiltersToURL({
        assetType,
        categoryType,
        params: paramsWithOffset,
      })
    );
  }

  const {
    data: assetsResponse,
    error: assetsError,
    mutate,
  } = useSWR<PIMAssetsResponse>(
    constructQuery({
      baseUrl: endpoints.v2_storefronts_id_pim_assets(tenant_id),
      query: debouncedSearchQuery,
      assetType: selectedAssetType,
      categoryType: selectedCategoryType,
    })
  );

  const { error: assetTypeError } = useSWR<AssetType>(
    endpoints.v2_storefronts_id_pim_asset_types(),
    {
      onSuccess: ({ asset_types }) => setAssetTypesFilters(asset_types),
    }
  );

  const assetTypeSearchParams = (() => {
    const params = new URLSearchParams();
    selectedAssetType.forEach((assetType) => {
      params.append("asset_type", assetType);
    });
    return params;
  })();
  const { error: categoryTypeError } = useSWR<AssetCategory>(
    selectedAssetType.length > 0
      ? `${endpoints.v2_tenants_id_pim_assets_categories(
          tenant_id
        )}?${assetTypeSearchParams}&deduped=true`
      : `${endpoints.v2_tenants_id_pim_assets_categories(
          tenant_id
        )}?deduped=true`,
    {
      onSuccess: ({ asset_categories }) =>
        setAssetCategoryTypesFilters(asset_categories.map(({ name }) => name)),
    }
  );

  const isLoading = !assetsResponse && !assetsError;

  const hasAssetFilters = !isEmpty(assetTypesFilters);
  const hasCategoryFilters = !isEmpty(assetCategoryTypesFilters ?? []);
  const allFiltersAreEmpty = !hasAssetFilters && !hasCategoryFilters;

  //table
  const tableColumns = React.useMemo(
    () => [
      {
        Header: t("Name"),
        accessor: "name",
        Cell: ({
          value,
          row: {
            original: { asset_type, signed_url, is_generated, content_type },
          },
        }: {
          value: string;
          row: { original: Assets };
        }) => (
          <AssetNameIcon
            asset_type={asset_type}
            signed_url={signed_url}
            name={value}
            is_downloadable={true}
            is_generated={is_generated}
            content_type={content_type}
          />
        ),
      },
      {
        Header: t("Asset Type"),
        accessor: "asset_type",
        Cell: ({ value }: { value: SupportedAssetType }) => (
          <span>{toTitleCase(value)}</span>
        ),
      },
      {
        Header: t("Category"),
        accessor: "asset_category",
      },
      {
        Header: t("# of Products"),
        accessor: "number_of_products",
      },
      {
        Header: t("Language"),
        accessor: "language",
      },
      // {
      //   Header: t("Region"),
      //   accessor: "region",
      // },
      {
        Header: t("Last Modified"),
        accessor: "modified_at",
      },
      {
        Header: t("Last Modified By"),
        accessor: "modified_by",
      },
      {
        Header: t("Actions"),
        accessor: "info",
        disableSortBy: true,
        Cell: ({ row }: { row: RowType<Assets> }) => (
          <IconWrapper>
            {hasPermission("modify_assets") && (
              <>
                <div
                  data-for={`${row.original.id}_tooltip`}
                  data-tip={
                    row.original.is_generated &&
                    row.original.number_of_products < 1
                      ? t(
                          "This asset does not belong to any products and cannot be edited."
                        )
                      : ""
                  }
                >
                  <EditButton
                    width={20}
                    height={20}
                    disabled={
                      row.original.is_generated &&
                      row.original.number_of_products < 1
                    }
                    onClick={() => handleEdit(row)}
                  />
                </div>
                <ReactTooltip id={`${row.original.id}_tooltip`} />
              </>
            )}
            {hasPermission("delete_assets") && (
              <DeleteButton
                width={20}
                height={20}
                onClick={() => handleDelete(row)}
              />
            )}
          </IconWrapper>
        ),
      },
    ],
    [t, hasPermission]
  );

  useEffect(() => {
    const handleProductsData = ({ data, pagination }: PIMAssetsResponse) => {
      setTableData(
        data.map((asset) => {
          const {
            id,
            name,
            asset_category,
            category,
            language,
            asset_type,
            modified_at,
            modified_by,
            is_generated,
            number_of_products,
            signed_url,
            ...restProps
          } = asset;
          return {
            id,
            asset_category,
            category,
            language: language?.toLocaleUpperCase() ?? "--",
            name: name ?? "--",
            modified_at: formatDate(modified_at),
            modified_by: modified_by ?? "--",
            is_generated,
            asset_type,
            number_of_products,
            signed_url,
            ...restProps,
          };
        })
      );
      setTablePagination({
        perPage: perPage,
        pageCount: Math.ceil(pagination.total / perPage),
        pageIndex: pagination.offset / perPage + 1,
      });
    };

    if (assetsResponse) {
      const { data: assets, pagination } = assetsResponse;
      handleProductsData({ data: assets, pagination });
    }
  }, [assetsResponse, perPage, supported_languages]);

  useEffect(() => {
    setQuery({ offset });
  }, [offset, setQuery]);

  useEffect(() => {
    if (debouncedSearchQuery === "") setQuery({ q: undefined });
    if (debouncedSearchQuery) {
      setQuery({ q: debouncedSearchQuery });
    }
  }, [setQuery, debouncedSearchQuery]);

  useEffect(() => {
    setAssetTypesFromURL(
      getObjArrayFromStringArray(
        ((query.asset_type ?? []) as string[]).map((assetType) =>
          toTitleCase(assetType)
        )
      )
    );
    setcategoryTypesFromURL(
      getObjArrayFromStringArray((query.asset_category ?? []) as string[])
    );
  }, [query]);

  const handleBulkUpload = () => {
    history.push(`${adminPath}/pim/assets/uploads`);
  };

  const handleAssociateProducts = () => {
    //  The endpoint for associating products is to be used here..
    history.push(`${adminPath}/pim/assets/associate-products`);
  };

  return (
    <PageWrapper>
      <PageTitle style={{ margin: "0px" }}>{t("Assets")}</PageTitle>
      <FullWidthHorizontalSeparator />
      <ListGridToggleButton
        name="assets_list_grid_toggle"
        set_toggle_state={set_list_grid_toggle}
      />
      <Flex style={{ marginTop: "30px" }}>
        <Flex2>
          <SearchBar
            query={searchQuery}
            placeHolder={t("Search by Asset Name")}
            handleChange={handleSearch}
            handleClearInput={handleClearSearch}
            maxWidth="450px"
          />
        </Flex2>
        {/* All sellers can import or create new products. */}
        <Flex1 style={{ textAlign: "right" }}>
          <div
            data-alignright
            style={{ display: "flex", justifyContent: "end" }}
          >
            <WithPermission permission="modify_assets">
              <ActionButtonsContainer>
                <AddAssetButtonWrapper>
                  <PrimaryButtonWithPlusIcon
                    onClick={() => setShowSlideOut("add")}
                    style={{ padding: "8px 12px" }}
                  >
                    {t("Add asset")}
                  </PrimaryButtonWithPlusIcon>
                </AddAssetButtonWrapper>
                <BulkActionWrapper>
                  <BulkActionDropdown
                    onBulkUpload={handleBulkUpload}
                    onAssociateProducts={handleAssociateProducts}
                  />
                </BulkActionWrapper>
              </ActionButtonsContainer>
            </WithPermission>
          </div>
        </Flex1>
      </Flex>
      {showSlideOut === "add" && (
        <SlideOut closeFlyout={closeCreateSlideOut} show={!!showSlideOut}>
          <NewAssetFormContainer>
            <NewAssetForm
              onComplete={closeCreateSlideOut}
              refreshParentData={mutate}
            />
          </NewAssetFormContainer>
        </SlideOut>
      )}
      {showSlideOut === "edit" && (
        <SlideOut closeFlyout={closeCreateSlideOut} show={!!showSlideOut}>
          <EditAsset
            onComplete={closeCreateSlideOut}
            refreshAssetsList={mutate}
            assetData={editRowData!}
          />
        </SlideOut>
      )}
      {showSlideOut === "generate" && (
        <SlideOut
          closeFlyout={async () => {
            closeCreateSlideOut();
            product_document_ref.current?.clear_poll_timeout();
            await mutate();
          }}
          show={!!showSlideOut}
        >
          <EditOrGenerateProductDocument
            asset={
              {
                ...editRowData,
                is_generated: true,
              } as Assets
            }
            ref={product_document_ref}
            onSuccess={async () => {
              await mutate();
              closeCreateSlideOut();
            }}
          />
        </SlideOut>
      )}
      {showDeleteModal && (
        <DeleteAssetModal
          onComplete={closeDeleteModal}
          show={showDeleteModal}
          onClose={closeDeleteModal}
          refreshAssetsList={mutate}
          assetData={deleteRowData}
        />
      )}
      <Flex style={{ margin: "24px 0px", justifyContent: "space-between" }}>
        <Flex1>
          {!assetTypeError && (
            <FilterBy
              setter={handleAssetType}
              filter="asset_type"
              options={getObjArrayFromStringArray(
                assetTypesFilters.map((type) => toTitleCase(type))
              )}
              header={t("Asset Type")}
              label={t("Asset Type")}
              preselectedFilters={
                isChipTypeArray(assetTypesFromURL) ? assetTypesFromURL : []
              }
            />
          )}
          {!categoryTypeError && (
            <FilterBy
              setter={handleCategoryType}
              filter="asset_category"
              options={getObjArrayFromStringArray(assetCategoryTypesFilters)}
              header={t("Category Type")}
              label={t("Category Type")}
              preselectedFilters={
                isChipTypeArray(categoryTypesFromURL)
                  ? categoryTypesFromURL
                  : []
              }
            />
          )}
          <TertiaryTextButtonSmall
            onClick={() => {
              handleClearFilters();
            }}
          >
            {!allFiltersAreEmpty && t("Clear Filters")}
          </TertiaryTextButtonSmall>
        </Flex1>
        <DropDownContainerWrapper>
          <DropDown
            items={perPageItems}
            activeItem={perPage}
            textLeft={t("items") + ":"}
            textRight={t("Per Page")}
            direction={"right"}
            className={"per_Page"}
            clickHandler={changePerPage}
          ></DropDown>
        </DropDownContainerWrapper>
      </Flex>
      <ContentWrapper>
        {list_grid_toggle === "list" ? (
          <Table
            columns={tableColumns}
            data={tableData}
            isLoading={isLoading}
            error={assetsError}
            handleSort={handleSort}
            lastChildleftAlign
          />
        ) : (
          <GridAssetView
            assets={
              [
                ...tableData.map((asset) => ({
                  ...asset,
                  can_edit: (asset as Assets).is_generated
                    ? hasPermission("modify_assets") &&
                      (asset as Assets).number_of_products > 0
                    : hasPermission("modify_assets"),
                  can_delete: hasPermission("delete_assets"),
                  can_change_visibility: false,
                  can_external_download: true,
                  can_view_details: true,
                })),
              ] as unknown as (Assets & GridItemProps)[]
            }
            on_edit_asset={(asset) =>
              handleEdit({ original: asset } as unknown as RowType<Assets>)
            }
            on_remove_asset={(asset) =>
              handleDelete({ original: asset } as unknown as RowType<Assets>)
            }
            on_download_asset={(asset) => {
              window.open(asset.signed_url, "_blank");
            }}
          />
        )}
        <Pagination
          pagination={tablePagination}
          offset={offset}
          handlePageClick={changePage}
        />
      </ContentWrapper>
    </PageWrapper>
  );
}
