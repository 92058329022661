import React, { useState, useCallback, useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import type { TFunction } from "react-i18next";

import {
  PrimaryButton,
  InvisibleButton,
  GoBackButtonSmall,
} from "../../../../../../components/Buttons/Buttons";
import { RadioButton } from "../../../../../../components/RadioButton/RadioButton";
import { RadioButtonContainer } from "../../../../../../layout/FormLayout";
import { useStoreState, useFormWrapper } from "../../../../../../util/util";
import { endpoints } from "../../../../../../endpoints";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { UploadedAssetsList } from "./UploadedAssetsList";
import type { UploadedAssetSchema } from "../../../../../../types/types.PIM";
import { Notifications } from "../../../../../../components/Notifications/NotificationsContext";
import {
  PageWrapper,
  ContentWrapper,
  PageTitle,
  FullWidthHorizontalSeparator,
} from "../../../../../../layout/portalPageLayout";
import { HelpDialog } from "../../../../../SharedPages/OrganizationPage/ProductsList/CreatePimProductPage/CreateFromUploads/HelpDialog";
import {
  InfoIcon,
  DownloadIcon,
  CSVIcon,
} from "../../../../../../components/Icons/Icons";
import { FilePickerUncontrolled } from "../../../../../../components/FilePicker/FilePickerUncontrolled";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { LoadingInfoModal } from "../../../../../../components/LoadingInfoModal/LoadingInfoModal";

const Container = styled(ContentWrapper)`
  padding: 20px 0;
  position: relative;
`;

const Description = styled.p`
  height: 38px;
  align-self: stretch;
  flex-grow: 0;
  font-family: Inter;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: #3f4a55;
`;

const BaseButton = styled.button`
  height: 40px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 8px 16px;
  border-radius: 4px;
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: normal;
  text-align: center;
`;

const CancelButton = styled(BaseButton)`
  width: 82px;
  border: solid 1px #cb2020;
  background-color: #fff;
  color: #cb2020;
`;

const UploadCSVButton = styled(BaseButton)`
  width: 119px;
  border: solid 1px #f5f7f8;
  background-color: #f5f7f8;
  color: #0f1d2b;
  font-size: 14px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 24px;
`;

const BackButtonContainer = styled.div`
  margin-bottom: 10px;
`;

const TitleContainer = styled.div`
  margin-bottom: 10px;
`;

const CSVReadyContainer = styled.div`
  margin-top: 20px;
`;

const Title = styled.h2`
  width: 179px;
  height: 24px;
  flex-grow: 0;
  font-family: Inter;
  font-size: 19px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.26;
  letter-spacing: normal;
  text-align: left;
  color: #0f1d2b;
`;

const ViewGuideIcon = styled.label`
  width: 82px;
  height: 23px;
  flex-grow: 0;
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: normal;
  text-align: right;
  color: #0f1d2b;
`;
const HelpText = styled.label`
  width: 165px;
  height: 19px;
  flex-grow: 0;
  font-family: Inter;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: #6f777f;
`;
const HelpIcon = styled(InfoIcon)`
  width: 15.2px;
  height: 15.2px;
  flex-grow: 0;
`;

const DownloadCSVTextContainer = styled.div`
  margin: 24px 0;
`;

const DownloadCSVText = styled.label`
  width: 91px;
  height: 19px;
  flex-grow: 0;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: -0.15px;
  text-align: left;
  color: #0f1d2b;
`;

const RadioButtonContainerWithSpacing = styled(RadioButtonContainer)`
  margin-bottom: 24px;
`;

const FileName = styled.span`
  flex-grow: 1;
  color: ${({ theme }) => theme.primaryTextColor};
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  margin-left: 16px; // Added margin to create gap on the left from CSV icon
`;

const DownloadButton = styled(BaseButton)`
  width: 104px;
  border: solid 1px #0090e3;
  background-color: #f8fcff;
  color: #0f1d2b;
`;

const DoneButton = styled(PrimaryButton)`
  padding: 10px 20px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;
const NextStepsTitle = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.primaryTextColor};
  margin-bottom: 16px;
`;

const NextStepsList = styled.ol`
  padding-left: 20px;
  margin-bottom: 24px;
`;

const NextStepItem = styled.li`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.secondaryTextColor};
  font-size: 15px;
`;

const UploadCSVContainer = styled.div`
  margin-top: 20px;
  max-width: 600px;
`;

const StyledFilePicker = styled(FilePickerUncontrolled)`
  .file-picker-dropzone {
    width: 100%; // This ensures the dropzone takes full width of its container
    height: 135px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 32px 24px;
    border-radius: 4px;
    border: solid 1px #cfd1d4;
    background-color: #fff;
  }
`;

const GuideSection = styled.section`
  position: absolute;
  right: 0;
  top: 40;
`;

const GuideContainer = styled.div`
  width: 165px;
  height: 50px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  font-size: 15px;
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FullWidthContainer = styled.div`
  width: 100%;
`;

const LanguageText = styled.span`
  width: 75px;
  height: 19px;
  flex-grow: 0;
  font-family: Inter;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: #6f777f;
`;

const StyledCSVIcon = styled(CSVIcon)`
  margin-right: 12px;
`;

const DocumentRow = styled.div`
  width: 100%;
  min-width: 396px;
  max-width: 600px;
  height: auto;
  min-height: 48px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
  border-radius: 4px;
  border: solid 1px #e7e8ea;
  background-color: #fff;
  max-width: 53%;

  ${LanguageText} {
    margin-left: 12px; // Added margin to create gap on the left
    margin-right: 16px;
    white-space: nowrap;
  }

  ${InvisibleButton} {
    margin-left: 16px;
    margin-right: 12px;
  }
`;

type ViewState = "select" | "csvReady" | "upload";

const UploadSchema = z.object({
  file: z.instanceof(File).nullable(),
});

export const AssociateProducts: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [viewState, setViewState] = useState<ViewState>("select");
  const [selectedOption, setSelectedOption] =
    useState<"all" | "previous">("all");
  const [selectedUploads, setSelectedUploads] = useState<UploadedAssetSchema[]>(
    []
  );
  const { tenant_id } = useStoreState();
  const [csvContent, setCsvContent] = useState<string | null>(null);
  const [csvFileName, setCsvFileName] = useState("");
  const { notifyError, notifySuccess } = useContext(Notifications);
  const [isLoading, setIsLoading] = useState(false);
  const [showGuide, setShowGuide] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);

  const methodsOfUseForm = useFormWrapper({
    resolver: zodResolver(UploadSchema),
    defaultValues: {
      file: null,
    },
  });

  const { handleSubmit, watch } = methodsOfUseForm;
  const file = watch("file");

  const handleGenerateCSV = async () => {
    try {
      setIsLoading(true);

      const baseEndpoint =
        endpoints.v2_tenants_tenant_id_pim_assets_uploads_download(tenant_id);
      const scope = selectedOption === "all" ? "all" : "selected";
      const endpoint = `${baseEndpoint}?scope=${scope}`;

      let requestData = {};
      if (scope === "selected") {
        requestData = {
          data: selectedUploads.map((upload) => upload.id),
        };
      }

      const response = await axios.post(endpoint, requestData, {
        responseType: "blob",
      });

      const content = await response.data.text();
      const fileName =
        scope === "all"
          ? "all_assets_product_association.csv"
          : "selected_assets_product_association.csv";

      setCsvContent(content);
      setCsvFileName(fileName);
      setViewState("csvReady");
    } catch (error) {
      notifyError("Error generating CSV");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = () => {
    if (csvContent) {
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", csvFileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(url);

      setCsvContent(null);
      setCsvFileName("");
      setViewState("select");
    }
  };

  const handleUpload = async (data: { file: File | null }) => {
    if (!data.file) {
      notifyError(t("No file selected"));
      return;
    }

    setShowUploadModal(true);
    const formData = new FormData();
    formData.append("file", data.file);

    try {
      const response = await axios.post(
        endpoints.v2_tenants_tenant_id_pim_assets_associate(tenant_id),
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (response.status === 200) {
        notifySuccess(t("Products associated successfully"));
        setViewState("select");
      }
    } catch (error) {
      notifyError(t("Error uploading file"));
    } finally {
      setShowUploadModal(false);
    }
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const option = event.target.value as "all" | "previous";
    setSelectedOption(option);
    if (option === "all") {
      setSelectedUploads([]);
    }
  };

  const handleBack = () => {
    if (viewState === "upload") {
      setViewState("select");
    } else if (viewState === "csvReady") {
      setViewState("select");
    } else {
      history.goBack();
    }
  };

  const handleSelectUploads = useCallback(
    (selectedAssets: UploadedAssetSchema[]) => {
      setSelectedUploads(selectedAssets);
    },
    []
  );

  const renderSelectView = () => (
    <FullWidthContainer>
      <Title>{t("Associate Products")}</Title>
      <Description>
        {t(
          "Download the list of assets via CSV and upload it back once the product id's are"
        )}
        <br />
        {t("listed against the individual asset")}
      </Description>
      <DownloadCSVTextContainer>
        <DownloadCSVText>{t("Download CSV")}</DownloadCSVText>
      </DownloadCSVTextContainer>
      <RadioButtonContainerWithSpacing>
        <RadioButton
          name="assetSelection"
          value="all"
          optionTitle={t("Select all assets")}
          handleChange={handleOptionChange}
          checked={selectedOption === "all"}
        />
        <RadioButton
          name="assetSelection"
          value="previous"
          optionTitle={t("Select from previous uploads")}
          handleChange={handleOptionChange}
          checked={selectedOption === "previous"}
        />
      </RadioButtonContainerWithSpacing>
      {selectedOption === "previous" && (
        <FullWidthContainer>
          <UploadedAssetsList
            setStage={() => {}}
            onSelect={handleSelectUploads}
            isCheckBoxRequired={true}
          />
        </FullWidthContainer>
      )}
      <ButtonContainer>
        <CancelButton onClick={handleBack}>{t("Cancel")}</CancelButton>
        <UploadCSVButton onClick={() => setViewState("upload")}>
          {t("Upload CSV")}
        </UploadCSVButton>
        <DownloadButton
          onClick={handleGenerateCSV}
          disabled={
            selectedOption === "previous" && selectedUploads.length === 0
          }
        >
          {t("Download")}
        </DownloadButton>
      </ButtonContainer>
    </FullWidthContainer>
  );

  const renderCSVReadyView = () => (
    <CSVReadyContainer>
      <Title>{t("Assets Data Ready!")}</Title>
      <Description>
        {t(
          "Your assets file is ready for download. You can now download the file and proceed"
        )}
        <br />
        {t("to upload")}
      </Description>
      <DocumentRow>
        <StyledCSVIcon />
        <FileName>{csvFileName}</FileName>
        <LanguageText>{t("English (EN)")}</LanguageText>
        <InvisibleButton type="button" onClick={handleDownload}>
          <DownloadIcon width={16} height={16} />
        </InvisibleButton>
      </DocumentRow>
      <NextStepsTitle>{t("Next Steps")}</NextStepsTitle>
      <NextStepsList>
        <NextStepItem>
          {t("Click the download icon to download the CSV file .")}
        </NextStepItem>
        <NextStepItem>
          {t("List the product id's against the assets file name.")}
        </NextStepItem>
        <NextStepItem>
          {t(
            "Once the CSV is listed with all the product id's against the assets, return to"
          )}
          <br />
          {t(
            "the associate products page and choose to upload the CSV and finalize"
          )}
          <br />
          {t("the process.")}
        </NextStepItem>
      </NextStepsList>
      <DoneButton onClick={handleDownload}>{t("Done")}</DoneButton>
    </CSVReadyContainer>
  );

  const renderUploadView = () => (
    <UploadCSVContainer>
      <Title>{t("Upload CSV")}</Title>
      <Description>
        {t("Upload the assets CSV to associate the assets to products")}
      </Description>
      <form onSubmit={handleSubmit(handleUpload)}>
        <StyledFilePicker
          methodsOfUseForm={methodsOfUseForm}
          name="file"
          accept={[".csv"]}
          placeHolderText={t("Drag and drop CSV file here or")}
          description={t("Only CSV files are accepted")}
          required={true}
        />
        <ButtonContainer>
          <CancelButton onClick={handleBack}>{t("Cancel")}</CancelButton>
          <PrimaryButton type="submit" disabled={!file}>
            {t("Continue")}
          </PrimaryButton>
        </ButtonContainer>
      </form>
    </UploadCSVContainer>
  );

  const guidelineOverview = (t: TFunction) => [
    t(
      "Each CSV will have assets file name, type, category and language. You can download all assets by selecting all assets or choose to select assets from previous uploads."
    ),
  ];

  const csvGuidelines = (t: TFunction) => [
    t("Do not modify the header row."),
    t("Do not change the file name of the CSVs."),
    t(
      "Input the Product ID to associate the respective product to the asset under the Product ID header"
    ),
    t(
      "If there are more that one products, input product id separated by comma (,)."
    ),
    t(
      "Once the CSV is updated with the product id, upload the CSV to associate products to the assets."
    ),
  ];

  return (
    <PageWrapper>
      <BackButtonContainer>
        <InvisibleButton onClick={handleBack}>
          <GoBackButtonSmall text={t("Back")} />
        </InvisibleButton>
      </BackButtonContainer>
      <TitleContainer>
        <PageTitle>{t("Associate Products")}</PageTitle>
      </TitleContainer>
      <FullWidthHorizontalSeparator />
      <Container>
        <PageContent>
          {viewState === "select" && renderSelectView()}
          {viewState === "csvReady" && renderCSVReadyView()}
          {viewState === "upload" && renderUploadView()}
          <GuideSection>
            <GuideContainer>
              <HelpDialog
                show={showGuide}
                setShow={setShowGuide}
                overview={guidelineOverview}
                guidelines={csvGuidelines}
                guidelineHeader={t("Guidelines for Updating Your CSV Files")}
              />
              <HelpText>{t("Need help getting started?")}</HelpText>
              <div
                onClick={() => setShowGuide(true)}
                style={{
                  display: "flex",
                  gap: "4px",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <HelpIcon />
                <ViewGuideIcon>{t("View Guide")}</ViewGuideIcon>
              </div>
            </GuideContainer>
          </GuideSection>
        </PageContent>
        <LoadingInfoModal
          isOpen={isLoading}
          onClose={() => setIsLoading(false)}
          title={t("Your Data is Being Prepared")}
          description={t(
            "Please wait while we prepare your data for download. This process ensures that all information is accurate and up-to-date. The download should be ready shortly. Thank you for your patience!"
          )}
          isLoading={true}
        />
        <LoadingInfoModal
          isOpen={showUploadModal}
          onClose={() => setShowUploadModal(false)}
          title={t("Uploading CSV")}
          description={t(
            "Please wait while we upload and process the CSV. This process may take a few moments, depending on the size of your data. We are ensuring that all information is correctly uploaded and integrated into the system."
          )}
          isLoading={true}
        />
      </Container>
    </PageWrapper>
  );
};
