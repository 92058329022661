import React from "react";
import styled from "styled-components";
import { XIcon } from "../Icons/Icons";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(15, 29, 43, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 900;
`;

const ModalContainer = styled.div`
  width: 600px;
  height: 289px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 16px;
  padding: 32px 24px;
  border-radius: 8px;
  border: solid 1px #cfd1d4;
  background-color: #fff;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

const ContentSection = styled.section`
  height: 185px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  border: solid 1px #0090e3;
`;

const Title = styled.h2`
  font-family: Inter, sans-serif;
  font-size: 19px;
  font-weight: 600;
  line-height: 1.26;
  color: #000;
  margin: 0;
`;

const Description = styled.p`
  font-family: Inter, sans-serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.53;
  letter-spacing: -0.15px;
  text-align: center;
  color: #0f1d2b;
  margin: 0;
`;

const LoadingCircle = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid rgba(0, 144, 227, 0.3);
  border-top: 2px solid #0090e3;
  border-radius: 50%;
  align-self: center;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface LoadingInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: string;
  isLoading: boolean;
}

export const LoadingInfoModal: React.FC<LoadingInfoModalProps> = ({
  isOpen,
  onClose,
  title,
  description,
  isLoading,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <ModalOverlay>
      <ModalContainer>
        <CloseButton onClick={onClose}>
          <XIcon />
        </CloseButton>
        <ContentSection>
          <Title>{title}</Title>
          {isLoading && <LoadingCircle />}
          <Description>{description}</Description>
        </ContentSection>
      </ModalContainer>
    </ModalOverlay>
  );
};
