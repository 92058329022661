import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import type { TFunction } from "react-i18next";
import styled from "styled-components/macro";
import {
  ArchiveButton,
  ButtonWithConfirmDialog,
  EditButton,
  PrimaryButtonFitContainer,
  SecondaryButtonFitContainer,
} from "../../../../../components/Buttons/Buttons";
import {
  CaretRight,
  SystemDefaultIcon,
} from "../../../../../components/Icons/Icons";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import type {
  ListPatchArgSchema,
  PaginatedListSchema,
  ShortListSchema,
} from "../../../../../types/types.PIM";
import type { AxiosError } from "axios";
import Axios from "axios";
import { endpoints } from "../../../../../endpoints";
import {
  checkTextOverflow,
  formatDateTime,
  useFormWrapper,
  useStoreState,
} from "../../../../../util/util";
import { zodResolver } from "@hookform/resolvers/zod";
import ReactTooltip from "react-tooltip";
import type { ListItemFormValue } from "./ListHome";
import { IconContainer } from "./ListHome";
import { SmallSectionHeaderRegular } from "../../../../../components/Typography/Typography";
import type { DataMutate } from "../../../../../types/types";
import { SystemIconWrapper } from "../components/PIM.components.util";
import { useAuthContext } from "../../../../../components/Auth";
import { SlideOut } from "../../../../../components/SlideOut/SlideOut";
import { Flex, Form } from "../../../../../layout/FormLayout";
import { HeaderLeft, PageTitle } from "../../../../../components/Layout/Layout";
import { TextField } from "../../../../../components/TextFields/TextFields";
import { ToggleSwitchV2 } from "../../../../../components/ToggleSwitch/ToggleSwitch";
import { InfoBlockSmall } from "../../../../../components/InfoBlocks/InfoBlocks";
import { z } from "zod";
import { zodRequiredString } from "../../../../../util/zod.util";

export const ListItemBase = styled.div`
  height: 58px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  cursor: pointer;
  > h3 {
    flex: 1 0 0.5;
  }
  border-top: ${({ theme }) => `0.5px solid ${theme.secondaryBorder}`};
  border-bottom: ${({ theme }) => `0.5px solid ${theme.primaryBG}`};
  border-right: ${({ theme }) => `2px solid ${theme.secondaryBorder}`};
  border-left: ${({ theme }) => `3px solid ${theme.secondaryBorder}`};
  background-color: ${({ theme }) => theme.colors.white};
`;

export const ListItemWrapper = styled(ListItemBase)<{ active?: boolean }>`
  > div {
    > button,
    button:disabled {
      opacity: 0;
    }
  }
  &:hover {
    > div {
      > button {
        opacity: 1;
      }
      > button:disabled {
        opacity: 0.5;
      }
    }
  }
  border-top: ${({ theme, active }) =>
    active
      ? `1px solid ${theme.brandColor}`
      : `0.5px solid ${theme.secondaryBorder}`};
  border-bottom: ${({ theme, active }) =>
    active ? `1px solid ${theme.brandColor}` : `0.5px solid transparent`};
  border-right: ${({ theme, active }) =>
    active
      ? `2px solid ${theme.brandColor}`
      : `2px solid ${theme.secondaryBorder}`};
  border-left: ${({ theme, active }) =>
    active
      ? `3px solid ${theme.brandColor}`
      : `3px solid ${theme.secondaryBorder}`};
  background-color: ${({ theme, active }) =>
    active ? theme.primaryButtonBG : theme.colors.white};
  justify-content: flex-start;
`;

export const ListItemName = styled(SmallSectionHeaderRegular)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
`;

export const ListSchemaFn = (t: TFunction, maxLength?: number) =>
  z.object({
    name: zodRequiredString(t).max(
      maxLength ?? 25,
      t("Maximum {{chars}} characters allowed", { chars: maxLength ?? 25 })
    ),
    icons_enabled: z.boolean(),
  });

export const ListItem = ({
  item,
  active,
  selectItem,
  mutateList,
}: {
  item: ShortListSchema;
  active: boolean;
  selectItem: (reset?: boolean) => void;
  mutateList: DataMutate<PaginatedListSchema>;
}) => {
  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();
  const { notifySuccess, notifyError } = useContext(Notifications);

  const editNameRef =
    useRef<{ setEditMode: (editMode: boolean) => void; focus: () => void }>(
      null
    );
  const nameRef = useRef<HTMLHeadingElement>(null);
  const { hasPermission } = useAuthContext();

  const { handleSubmit, register, formState, errors, watch } = useFormWrapper({
    resolver: zodResolver(ListSchemaFn(t)),
  });

  const is_icon_enabled = watch("icons_enabled");
  const handleDeleteListItem = async () => {
    try {
      await Axios.patch<ListPatchArgSchema, ShortListSchema>(
        endpoints.v2_tenants_id_or_slug_pim_lists_id(tenant_id, item.id),
        {
          is_deleted: true,
        }
      );
      notifySuccess(t("List archived successfully"));
      mutateList();
      selectItem(true);
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("Could not archive list. Something went wrong."),
        {
          error,
        }
      );
    }
  };

  const handleEditClick = () => {
    setEditMode(true);
    setTimeout(() => editNameRef.current?.setEditMode(true));
  };

  const handleEditListConfirm = async ({
    name,
    icons_enabled,
  }: ListItemFormValue) => {
    try {
      await Axios.patch<ListPatchArgSchema, ShortListSchema>(
        endpoints.v2_tenants_id_or_slug_pim_lists_id(tenant_id, item.id),
        {
          name: name.trim(),
          icons_enabled: icons_enabled,
        }
      );
      notifySuccess(t("List name updated successfully"));
      setEditMode(false);
      mutateList();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("Could not edit list. Something went wrong."),
        {
          error,
        }
      );
    }
  };

  const isTextOverflow = () =>
    nameRef.current ? checkTextOverflow(nameRef.current).widthOverflow : false;

  useEffect(() => {
    if (!active && editMode) {
      setEditMode(false);
    }
  }, [active, editMode]);

  return (
    <ListItemWrapper active={active} onClick={(e) => selectItem()}>
      <ListItemName
        ref={nameRef}
        data-for={`list-item-name-${item.id}-tooltip`}
        data-tip={isTextOverflow() ? `${item.name} (${item.count ?? 0})` : ""}
      >
        {`${item.name} (${item.count ?? 0})`}
      </ListItemName>
      <ReactTooltip id={`list-item-name-${item.id}-tooltip`} />
      <SystemIconWrapper
        style={{
          padding: 0,
          position: "relative",
          top: "2px",
        }}
        data-for={`system${item.id}`}
        data-tip={t("System Default")}
      >
        {item.is_system && <SystemDefaultIcon width={18} height={18} />}
      </SystemIconWrapper>
      {item.is_system && <ReactTooltip id={`system${item.id}`} />}
      <IconContainer>
        {!item.is_system && (
          <>
            {hasPermission("delete_lists") && (
              <ButtonWithConfirmDialog
                Button={(props) => (
                  <>
                    <ArchiveButton
                      {...props}
                      width={24}
                      height={24}
                      datafor={`list-item-${item.id}-delete-tooltip`}
                      datatip={
                        item.is_system
                          ? t("You cannot archive system default list")
                          : ""
                      }
                    />
                    <ReactTooltip id={`list-item-${item.id}-delete-tooltip`} />
                  </>
                )}
                testid={`list-item-${item.id}`}
                disabled={item.is_system}
                handleConfirm={handleDeleteListItem}
                confirmMessage={t(
                  "Are you sure you want to archive this list, {{itemName}}?",
                  { itemName: item.name }
                )}
              />
            )}
            {hasPermission("modify_lists") && (
              <EditButton
                onClick={handleEditClick}
                width={24}
                height={24}
                datafor={`list-item-${item.id}-tooltip`}
                datatip={
                  item.is_system
                    ? t("You cannot edit a system default list")
                    : ""
                }
                disabled={item.is_system}
              />
            )}
          </>
        )}
        <CaretRight />
        <ReactTooltip id={`list-item-${item.id}-tooltip`} />
      </IconContainer>

      {item && (
        <SlideOut show={!!editMode} closeFlyout={() => setEditMode(false)}>
          <HeaderLeft>
            <PageTitle>{t("Edit List")}</PageTitle>
          </HeaderLeft>
          <Form noValidate onSubmit={handleSubmit(handleEditListConfirm)}>
            <TextField
              name="name"
              label={t("List Name")}
              defaultValue={item.name}
              theref={register({
                required: true,
              })}
              formState={formState}
              errors={errors}
              type="text"
            />
            <div style={{ marginBottom: "25px" }}>
              <ToggleSwitchV2
                label={t("Enable icons for list values")}
                name="icons_enabled"
                defaultChecked={item.icons_enabled}
                ref={register({ required: true })}
                checked={is_icon_enabled}
                style={{ marginBottom: "30px" }}
              />
            </div>
            <InfoBlockSmall
              header={t("List Values")}
              content={item.count.toString()}
            />
            <InfoBlockSmall
              header={t("Created On")}
              content={formatDateTime(item.created_at) || "-"}
            />
            <InfoBlockSmall
              header={t("Created By")}
              content={item.created_by || "-"}
            />
            <InfoBlockSmall
              header={t("Last Modified On")}
              content={formatDateTime(item.created_at) || "-"}
            />
            <InfoBlockSmall
              header={t("Last Modified By")}
              content={item.modified_by || "-"}
            />

            <Flex style={{ marginTop: "30px" }}>
              <SecondaryButtonFitContainer
                onClick={() => setEditMode(false)}
                style={{ marginRight: "10px" }}
              >
                {t("Cancel")}
              </SecondaryButtonFitContainer>
              <PrimaryButtonFitContainer
                type="submit"
                style={{ marginLeft: "10px" }}
              >
                {t("Save")}
              </PrimaryButtonFitContainer>
            </Flex>
          </Form>
        </SlideOut>
      )}
    </ListItemWrapper>
  );
};
