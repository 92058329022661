import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../Auth";

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const StyledButton = styled.div`
  width: 135px;
  height: 40px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 4px 16px;
  border-radius: 4px;
  border: solid 2px #0090e3;
  background-color: var(--cerulean-3);
`;

const BulkActionText = styled.label`
  width: 81px;
  height: 23px;
  flex-grow: 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: normal;
  text-align: right;
  color: #0f1d2b;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: ${({ theme }) => theme.primaryBG};
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 100;
  margin-top: 4px;
  white-space: nowrap;
  width: 100%;
`;

const DropdownItem = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  text-align: left;
  font-size: 13px;
  font-family: Inter, sans-serif;
  color: #0f1d2b;
  &:hover {
    background-color: ${({ theme }) => theme.secondaryBG};
  }
`;

const StyledSvg = styled.svg`
  width: 20px;
  height: 20px;
  flex-grow: 0;
`;

type BulkActionDropdownProps = {
  onBulkUpload: () => void;
  onAssociateProducts: () => void;
};

export const BulkActionDropdown: React.FC<BulkActionDropdownProps> = ({
  onBulkUpload,
  onAssociateProducts,
}) => {
  const { t } = useTranslation();
  const { hasPermission } = useAuthContext();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleActionSelect = (action: string) => {
    setIsOpen(false);
    if (action === t("Bulk Upload")) {
      onBulkUpload();
    } else if (action === t("Associate Products")) {
      onAssociateProducts();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropdownItems = [
    t("Bulk Upload"),
    ...(hasPermission("modify_assets") ? [t("Associate Products")] : []),
  ];

  return (
    <DropdownContainer ref={dropdownRef}>
      <StyledButton onClick={toggleDropdown}>
        <BulkActionText>{t("Bulk Action")}</BulkActionText>
        <StyledSvg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5 7l5 5 5-5"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </StyledSvg>
      </StyledButton>
      {isOpen && (
        <DropdownMenu>
          {dropdownItems.map((item) => (
            <DropdownItem key={item} onClick={() => handleActionSelect(item)}>
              {item}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </DropdownContainer>
  );
};
