import { TertiaryTextButtonSmall } from "../Buttons/Buttons";
import type {
  TagClassificationConfig,
  Filters,
  ProductFiltersMainSchema,
  SupportedLanguage,
  WithPagination,
  FilterGroup,
} from "../../types/types";
import { FiltersWrapper } from "../../layout/publicPageLayout";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import useSWR from "swr";
import { ArrayParam, StringParam, useQueryParams } from "use-query-params";
import type { ChipType } from "../Chips/Chips";
import { endpoints } from "../../endpoints";
import {
  convertToChipArray,
  getCustomLabel,
  makeUrlWithParams,
  useStoreState,
} from "../../util/util";
import { FilterBy } from "../FilterBy/FilterBy";
import { useCookies } from "react-cookie";
import noop from "lodash/noop";
import {
  applyFiltersToURL,
  groupPortfolioParamsByKey,
} from "../../pages/public";
import { useLocation } from "react-router-dom";
import { LoadingIcon } from "../Icons/Icons";
import type {
  ProductStatusType,
  ProductStatusesSchema,
  AttributeTemplateSummarySchema,
  Assets,
} from "../../types/types.PIM";
import { getProductStatusText, getProductStatusValue } from "../Status/Status";
import { useAuthContext } from "../Auth";
import { screenSize } from "../../theme";
import { SoftHeaderRegular } from "../Typography/Typography";
import { SearchFilterTag } from "./SearchFilterTag";
import { DocumentFilterTag } from "./DocumentFilterTag";

export function chipsToStringArray(chips: ChipType[]) {
  return chips.map((chip) => chip.name);
}

const EmptyFiltersPlaceholder = styled.div`
  width: 100%;
  height: 30px;
  margin: 0 0 35px;
`;

const SearchFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 20px 0;
  padding-right: 8px;
  @media ${screenSize.medium} {
    flex-direction: row;
    margin-bottom: 0;
    overflow-x: scroll;
    gap: 8px;
  }
`;
const SearchFilterItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media ${screenSize.medium} {
    border-right: ${({ theme }) => `1px solid ${theme.primaryBorder}`};
  }
`;

const SearchFilterTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const FilterNameWrapper = styled(SoftHeaderRegular)`
  border-bottom: ${({ theme }) => `1px solid ${theme.primaryBorder}`};
  @media ${screenSize.medium} {
    border-bottom: none;
  }
`;

type FilterAreaProps = {
  resetOffset?: React.Dispatch<React.SetStateAction<number>>;
  clearFilter: () => void;
  customLabels: TagClassificationConfig[];
  showStatusFilter?: boolean;
  is_search_filter_popup?: boolean;
  exit_search_filter_popup?: () => void;
  showTemplateFilter?: boolean;
};

export function FiltersArea({
  resetOffset = noop,
  clearFilter,
  customLabels,
  showStatusFilter = false,
  is_search_filter_popup = false,
  exit_search_filter_popup,
  showTemplateFilter = false,
}: FilterAreaProps) {
  // "ArrayParam" is how useQueryParams handles things like
  // ?market_segment=abc&market_segment=xyz
  // That URL will become ["abc", "xyz"]
  const { search } = useLocation();
  const { roleIsSomeKindOfSeller } = useAuthContext();
  const [pageFilters, setPageFilters] = useState<Filters>();
  const queryObject = () => {
    const querylist: any = {
      active_tag_view: StringParam,
      q: StringParam,
    };
    if (pageFilters) {
      for (const [key] of Object.entries(pageFilters)) {
        querylist[key] = ArrayParam;
      }
    }
    return querylist;
  };

  const [query, setQuery] = useQueryParams(queryObject());
  const { storefront_id, slug: tenantSlug, tenant_id } = useStoreState();

  const { t } = useTranslation();
  const [cookies] = useCookies([`preferred-language-${tenantSlug}`]);

  const preferredLanguage: SupportedLanguage | undefined = cookies[
    `preferred-language-${tenantSlug}`
  ] as SupportedLanguage;

  function FilterPlaceHolder() {
    return (
      <FiltersWrapper>
        <LoadingIcon width={16} />
      </FiltersWrapper>
    );
  }

  const [filtersFromUrl, setFiltersFromUrl] =
    useState<{ [key: string]: string[] }>();

  useEffect(() => {
    const params = new URLSearchParams(search.substring(1));
    const filters = groupPortfolioParamsByKey(params);
    setFiltersFromUrl(filters);
  }, [search]);

  const { data, error: filtersError } = useSWR<ProductFiltersMainSchema, Error>(
    `${endpoints.v2_storefronts_id_products_filters(storefront_id)}?${
      is_search_filter_popup
        ? `q=${query.q}`
        : applyFiltersToURL({
            filtersFromUrl: filtersFromUrl,
            active_tag_view: query.active_tag_view,
            params: new URLSearchParams(),
          })
    }`
  );
  const filters = data?.filters;

  const { data: statusData } = useSWR<ProductStatusesSchema, Error>(
    roleIsSomeKindOfSeller && tenant_id && showStatusFilter
      ? endpoints.v2_tenants_tenant_id_pim_products_status(tenant_id)
      : null
  );
  const statuses = statusData?.statuses;

  const { data: templateData } = useSWR<
    WithPagination<{ data: AttributeTemplateSummarySchema[] }>,
    Error
  >(
    roleIsSomeKindOfSeller && tenant_id && showTemplateFilter
      ? `${endpoints.v2_storefronts_id_pim_templates_summary(
          tenant_id
        )}?minimum_number_of_products=1&limit=100`
      : null
  );

  const { data: documentsResponse } = useSWR<
    WithPagination<{ data: Assets[] }>
  >(
    is_search_filter_popup
      ? makeUrlWithParams(
          endpoints.v2_tenants_tenant_id_pim_products_document_search(
            tenant_id
          ),
          {
            limit: 15,
            q: query.q,
          }
        )
      : null
  );

  const handleFilter = ({
    values,
    filter,
  }: {
    values: ChipType[];
    filter: string;
  }) => {
    const filtersArray = chipsToStringArray(values);
    setQuery({
      [filter]:
        filtersArray.length > 0 && filter === "status"
          ? filtersArray.map((filter) => getProductStatusValue(filter, t))
          : filtersArray.length > 0
          ? filtersArray
          : undefined,
      ...(is_search_filter_popup && { active_tag_view: filter }),
    });
    if (is_search_filter_popup && exit_search_filter_popup) {
      exit_search_filter_popup();
    }
    resetOffset(0);
  };

  useEffect(() => {
    if (filters) {
      const filters_by_custom_labels: Filters = {};
      customLabels.forEach(({ filter_type }) => {
        if (filters[filter_type] && filters[filter_type].length > 0) {
          filters_by_custom_labels[filter_type] = filters[filter_type];
        }
      });
      if (statuses) {
        filters_by_custom_labels.status = (statuses as string[]).map(
          (item) => ({
            name: getProductStatusText(item as ProductStatusType, t),
            count: 0,
            image_url: null,
            selected: false,
          })
        );
      }
      if (templateData) {
        filters_by_custom_labels.template = templateData.data.map((item) => ({
          name: item.template_name,
          count: 0,
          image_url: null,
          selected: false,
        }));
      }
      setPageFilters(filters_by_custom_labels);
    }
  }, [customLabels, filters, statuses, templateData, t]);

  const handleClearFilters = () => {
    setQuery({
      active_tag_view: query.active_tag_view,
    });
    clearFilter();
  };

  const on_filter_item_click = (
    item: FilterGroup & { name: string },
    filter_name: string
  ) => {
    handleClearFilters();
    handleFilter({
      values: [{ name: item.name }],
      filter: filter_name,
    });
  };

  // Could potentially display an error notification after multiple failed
  // attempts. That is not yet set up in this project.
  if (!filters && !filtersError) {
    // stop layout from shifting on page load.
    return <FilterPlaceHolder />;
  } else if (filtersError) {
    // Search still works if filters don't load.
    // This maintains the layout of the page in case of error.
    return <EmptyFiltersPlaceholder />;
  } else if (filters) {
    // Handle case that happened once on staging where all filters were empty arrays.
    // This might theoretically be possible if a tenant is activated and no filters
    // are added.
    return (!!pageFilters && Object.entries(pageFilters).length > 0) ||
      (!!documentsResponse && documentsResponse.data.length > 0) ? (
      <>
        {is_search_filter_popup ? (
          <SearchFilterWrapper>
            {!!documentsResponse && documentsResponse.data.length > 0 && (
              <SearchFilterItem>
                <FilterNameWrapper>{t("Documents")}</FilterNameWrapper>
                <SearchFilterTags>
                  <DocumentFilterTag documents={documentsResponse.data} />
                </SearchFilterTags>
              </SearchFilterItem>
            )}
            {!!pageFilters &&
              Object.entries(pageFilters).map(([filter_name, filter_items]) => (
                <SearchFilterItem key={filter_name}>
                  <FilterNameWrapper>
                    {getCustomLabel({
                      filter_type: filter_name,
                      tag_classification_configs: customLabels,
                      preferred_language: preferredLanguage,
                    })}
                  </FilterNameWrapper>
                  <SearchFilterTags>
                    <SearchFilterTag
                      filter_items={filter_items}
                      on_filter_item_click={(item) =>
                        on_filter_item_click(item, filter_name)
                      }
                    />
                  </SearchFilterTags>
                </SearchFilterItem>
              ))}
          </SearchFilterWrapper>
        ) : (
          <FiltersWrapper>
            {!!pageFilters &&
              Object.entries(pageFilters).map(([filter_name, filter_items]) => {
                return (
                  <FilterBy
                    key={filter_name}
                    setter={handleFilter}
                    options={filter_items}
                    filter={filter_name}
                    header={getCustomLabel({
                      filter_type: filter_name,
                      tag_classification_configs: customLabels,
                      preferred_language: preferredLanguage,
                    })}
                    label={getCustomLabel({
                      filter_type: filter_name,
                      tag_classification_configs: customLabels,
                      preferred_language: preferredLanguage,
                    })}
                    preselectedFilters={
                      convertToChipArray(
                        filtersFromUrl && filter_name === "status"
                          ? filtersFromUrl[filter_name]?.map((option) =>
                              getProductStatusText(
                                option as ProductStatusType,
                                t
                              )
                            )
                          : filtersFromUrl
                          ? filtersFromUrl[filter_name]
                          : []
                      ) ?? []
                    }
                  />
                );
              })}

            {filtersFromUrl && Object.entries(filtersFromUrl)?.length > 0 && (
              <TertiaryTextButtonSmall
                onClick={() => {
                  handleClearFilters();
                }}
              >
                {t("Clear Filters")}
              </TertiaryTextButtonSmall>
            )}
          </FiltersWrapper>
        )}
      </>
    ) : (
      <></>
    );
  } else return null;
}
